@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;1,300;1,400;1,500;1,600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Mukta:wght@300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Hind:wght@300;400;500;600&display=swap');

* {
  margin: 0px;
  padding: 0px;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Hind', sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* a {
  text-decoration: none;
  color: auto;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 3px grey;
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #ff8f00;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #ff8f00;
}

.MuiDataGrid-columnHeaders {
  background-color: #ff8f00;
  color: #fff;
}

@media screen and (max-width: 900px) {

  /* Track */
  ::-webkit-scrollbar-track {
    display: none;
    box-shadow: inset 0 0 3px grey;
    border-radius: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track:hover {
    display: block;
    box-shadow: inset 0 0 3px grey;
    border-radius: 5px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    display: none;
    background: #ff8f00;
    border-radius: 5px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    display: block;
    background: #ff8f00;
  }
}

.MuiDrawer-paper {
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
}